.input_container {
  padding: 12px 40px;
  background-color: #f2f2f2;
  outline: none;
  border: 1px solid #8888;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
  font-weight: 500;
  width: 100%;
  color: black !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_container input {
  width: 100%;
  font-size: 18px;
}

.input_container input::placeholder {
  opacity: 0.5;
}

.input_container span {
  font-size: 20px;
  cursor: pointer;
}

.error {
  border-color: #c85245 !important;
}

@media (max-width: 480px) {
  .input_container {
    padding: 8px 20px;
  }

  .input_container span {
    font-size: 18px;
  }

  .input_container input {
    font-size: 16px;
  }
}
