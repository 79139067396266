.form_container {
  margin: 0 70px;
  background-color: white;
  border-radius: 12px;
  padding: 40px 80px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 40px;
}

.form_container form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_container {
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
}

.input_container a {
  color: #005561;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.error {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
  align-self: flex-end;
  color: #c85245;
}

.error_container {
  margin-bottom: 0px !important;
}

.input_container label {
  font-weight: bold;
}
.logo_container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: #888888;
  font-size: 20px;
  font-weight: 500;
}
.logo_container img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  color: #888888;
}

.logo_container .title {
  font-size: 32px;
  color: black;
  font-weight: bold;
}

.logo_container span {
  color: black;
  font-weight: bold;
}

.submit_button {
  font-size: 30px;
  font-weight: 500;
  background-color: #7d3c98;
  text-align: center;
  color: white;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0px;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
  margin-top: 28px;
  margin-bottom: 20px;
}

.submit_button:hover {
  background-color: #7c3c98e9;
}

.login_option {
  color: #888888;
  font-size: 20px;
  font-weight: 500;
}

.login_option a {
  color: #1b98a1 !important;
  font-weight: 700;
  cursor: pointer;
}

.price {
  display: flex;
  gap: 20px;
  align-self: self-end;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
}

.itemDescription {
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 20px;
}

.itemDescription p:first-child {
  font-weight: 600;
}

@media (width <=1000px) {
  .description{
    width: 100%;
    border-bottom: 2px solid grey;
  }
}

@media (width<=900px) {
  .form_container form {
    width: 100%;
  }
  .logo_container p {
    text-align: center;
  }
  .submit_button {
    width: 100%;
  }
}

@media (width <= 750px) {
  .form_container {
    margin: 0 22px;
    justify-content: center;
  }

  .price {
    font-size: 18px;
  }

  .itemDescription {
    font-size: 16px;
  }
}

@media (width <=480px) {
  .form_container{
    padding: 40px 20px;
  }

  .itemDescription {
    font-size: 14px;
  }

  .logo_container img {
    width: 100px;
    height: 100px;
  }
  .logo_container .title {
    font-size: 24px;
  }
  .logo_container {
    font-size: 16px;
  }
  .login_option {
    font-size: 16px;
    text-align: center;
  }
  .submit_button {
    font-size: 20px !important;
    width: 100%;
    padding: 4px 0px;
  }
}
