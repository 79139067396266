.profile_container {
  display: flex;
  justify-content: space-between;
  margin: 0 70px;
  height: fit-content;
}

.page_container {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border: rgba(0, 0, 0, 0.25) solid 1px;
  width: 80%;
  min-height: 100%;
  max-height: 688px;
  overflow: auto;
  padding: 30px 40px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  margin-left: 5px;
}

.navbar_container {
  width: 20%;
  height: 100%;
}

@media (width<=1140px) {
  .profile_container {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    margin: 0 20px;
  }

  .page_container {
    width: 100%;
    padding: 15px;
    border-radius: 25px;
  }

  .navbar_container {
    width: 100%;
    margin: 0 auto;
  }
}
