.CoursesContainer{
    margin: 0 70px;
}

.CoursesContainer h2 span{
    color: #c563ec;
}
.CoursesContainer .btn_container div{
    border-radius: 22px;
    border: 1px solid #c563ec;
    padding: 15px 50px;
}
.CoursesContainer .btn_container div a{
    font-weight: 600;
    color: #c563ec;
}

@media (width<=700px) {
    .CoursesContainer{
        margin: 0 22px;
    }
}
