.MainHeader_container{
    background-color: #f8fdfc;
}
.MainHeader_container img{
border-top-right-radius: 22px;
border-top-left-radius: 7px;
border-bottom-left-radius: 22px;
border-bottom-right-radius: 7px;
}
/* .MainHeader_container p{
    font-size: 38px;
} */
.MainHeader_container  span{
    color: #c563ec;
}
.MainHeader_container .header_paragraph{
    font-weight: 500;
    font-size: 28px;

}
.MainHeader_container .header_btn div{
    background-color: #c563ec;
    padding: 12px 22px;
    border-radius: 12px;
}
.MainHeader_container .header_btn div a{
    color: #fff;
}