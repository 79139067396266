.timer_container{
    background-color: white;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);
    width: fit-content;
    padding: 10px 40px ; 
    font-size: 20px;
    font-weight: 600;
    color: grey;
    border-radius: 25px;
}

.time_end{
    color: firebrick !important; 
}