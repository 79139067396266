.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  overflow: auto;
  border-radius: 0.5rem /* 8px */;
  background-color: whitesmoke;
  padding: 64px 48px;
  transition: all 500ms;
  border: 2px solid #7d3c98;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* backdrop-blur-sm  */
  z-index: 50;
  transition: all 500ms;
}

.closeButton {
  position: absolute;
  right: 1.5rem /* 32px */;
  top: 1rem /* 20px */;
  transform: translateX(12px);
  border-radius: 0.5rem /* 8px */;
  border: none;
  background: none;
  padding: 0.25rem /* 4px */;
  transition: all 200ms;
}

.closeButton:hover {
  background: rgb(229 231 235);
}

.closeIcon {
  width: 1.75rem /* 28px */;
  height: 1.75rem /* 28px */;
  color: rgb(107 114 128);
}
