.package_card {
  width: calc(90% / 3);
  border-radius: 25px;
  box-shadow: 2px 0px 8px 2px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title_container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-bottom: rgba(128, 128, 128, 0.402) solid 2px;
  padding: 50px;
  padding-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.title img {
  width: 58px;
  height: 58px;
}

.title p {
  font-size: 28px;
  font-weight: 600;
  margin-top: 15px;
}

.type {
  font-size: 26px;
  font-weight: 600;
  color: #9c4df4;
  text-align: center;
}

.details_container {
  padding: 20px 50px;
  border-bottom: rgba(128, 128, 128, 0.402) solid 2px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.details_container ul li {
  display: flex;
  align-items: center;
  gap: 15px;
}

.details_container ul li p {
  margin-top: 15px;
  font-weight: 500;
}

.price_container {
  padding: 50px;
  padding-top: 20px;
  text-align: center;
}

.price_container p {
  font-size: 22px;
  font-weight: bold;
  color: #9c4df4;
}

.btn1 {
  width: 100%;
  border-radius: 5px;
  background: #e9e0ec;
  padding: 10px 0;
  color: #9c4df4;
  font-weight: 600;
  transition: all;
  transition-duration: 500ms;
}

.btn1:hover {
  background: #e9e0ecb5;
}

.btn2 {
  width: 100%;
  border-radius: 5px;
  background: #5b2c6f;
  padding: 10px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all;
  transition-duration: 500ms;
}

.btn2:hover {
  background: #5b2c6fe5;
}

@media (width<=1200px) {
  .package_card {
    width: 100%;
  }
}

@media (width <=480px) {
  .title img {
    width: 40px;
    height: 40px;
  }

  .title p {
    font-size: 22px;
  }

  .type {
    font-size: 20px;
  }

  .title_container {
    gap: 15px;
  }

  .details_container {
    padding: 20px 30px;
  }

  .details_container ul li p {
    font-size: 16px;
  }

  .title_container {
    padding: 30px;
  }

  .price_container {
    padding: 30px;
  }

  .price_container p {
    font-size: 20px;
  }
}
