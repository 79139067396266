.lesson_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.lesson_tab,
.quiz_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 24px;
  font-weight: 600;
  padding: 15px 60px;
  border-radius: 25px;
  cursor: pointer;
  border: rgba(0, 0, 0, 0.4) solid 1px;
  transition: all;
  transition-duration: 500ms;
  color: rgba(42, 2, 59, 0.8);
}

.lesson_tab:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.quiz_tab {
  background-color: #e9e0ec;
  border: none;
  color: black;
}

.quiz_tab:hover {
  background-color: #e9e0ecc6;
}

.exam_btn {
  background-color: white;
  padding: 5px 40px;
  font-weight: 600;
  font-size: 20px;
  border: black solid 1px;
  color: #af8e09;
}

.exam_btn:hover {
  color: #af8e09;
}

.quiz_btn {
  width: 25%;
  font-size: 20px;
  font-weight: 500;
  background-color: #7d3c98;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 6px 0px;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
}

.quiz_btn:hover {
  background-color: #7c3c98e9;
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.level_form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 800px;
}

.level_form p {
  font-size: 22px;
  font-weight: 600;
  word-spacing: 5px;
}

.level_input label {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}

.tapTitle {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tapTitle p {
  margin: 0;
}

.tapTitle .subTitle {
  font-size: 18px;
  color: grey;
  font-weight: 500;
}

.freeTrier {
  font-size: 18px;
  color: grey;
  font-weight: 500;
}

.freeTrier button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: white;
  border: 1px solid black;
  font-weight: 600;
}

.timer_form {
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: center;
  justify-content: center;
  width: 800px;
}

.timer_form label {
  font-size: 22px;
  font-weight: 600;
}
@media (width <= 1150px) {
  .lesson_tab,
  .quiz_tab {
    flex-direction: column;
  }

  .exam_btn{
    margin-top: 10px;
  }
}
@media (width <= 600px) {
  .lesson_tab,
  .quiz_tab {
    padding: 10px 20px;
    font-size: 18px;
  }

  .exam_btn {
    padding: 5px 15px;
    font-weight: 600;
    font-size: 16px;
  }
}

@media (width<=400px) {
  .lesson_tab,
  .quiz_tab {
    font-size: 16px;
  }

  .exam_btn {
    padding: 5px 15px;
    font-weight: 500;
    font-size: 12px;
  }

  .freeTrier{
    font-size: 12px;
  }

  .tapTitle .subTitle{
    font-size: 12px;
  }
}
