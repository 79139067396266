.quiz_table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.quiz_table tr:first-child {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
}

.quiz_table tr:first-child td {
  padding: 10px;
}

.quiz_table tr {
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.quiz_table tr:first-child td {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.quiz_table tr td {
  padding: 5px 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.quiz_table tr td:first-child {
  width: calc(50% / 2);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.quiz_table tr td:last-child {
  width: 30%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.status_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.status p {
  margin-top: 15px;
}

.statusContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.statusItem {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px 8px;
  border-radius: 25px;
  transition: all;
  transition-duration: 500ms;
}

.statusItem:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.statusItem img {
  width: 24px;
  height: 24px;
}

.empty_table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 26px;
  color: red;
  word-spacing: 5px;
}

.lessonName .easy{
  color: seagreen;
}

.lessonName .medium{
  color: blue;
}

.lessonName .hard{
  color: firebrick;
}

@media (width<=750px) {
  .header {
    font-size: 24px;
  }

  .profile_quizes_container {
    gap: 15px;
    font-size: 16px;
  }

  .quiz_table tr td {
    font-size: 12px;
    padding: 5px;
    width: calc(100% / 5);
  }

  .quiz_table tr:first-child td {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .status {
    gap: 5px;
  }

  .status p {
    display: none;
  }

  .statusContainer {
    gap: 0px;
  }

  .statusItem img {
    width: 18px;
    height: 18px;
  }

  .statusItem span {
    font-size: 12px;
  }

  .quiz_table tr td {
    font-size: 12px;
  }

  .statusItem {
    padding: 8px 4px;
  }

  .empty_table {
    font-size: 20px;
  }
}
