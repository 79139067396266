.section_container {
  background: linear-gradient(
    to right,
    rgba(210, 180, 222, 1) 40%,
    rgba(210, 180, 222, 0.8) 60%,
    rgba(210, 180, 222, 0.6) 80%
  );
  margin: 0 70px;
  border-radius: 12px;
  padding: 12px 22px;
}

.title {
  font-size: 32px;
}

@media (width <= 750px) {
  .section_container {
    margin: 0 22px;
  }

  .section_container img {
    width: 200px;
    height: 75px;
  }

  .title {
    font-size: 26px;
  }

  .title_link {
    font-size: 14px;
    width: 100%;
  }
}

@media (width <= 600px) {
  .section_container {
    flex-direction: column;
    margin: 0 22px;
  }

  .section_container img {
    width: 100%;
  }
}
