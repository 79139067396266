.blog_img_container{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px ; 

}
.blog_img_container img{
    width: 75%;
    border-radius: 12px;

}
.item_container{
    height: 100%;
    background-color: #fff;
    box-shadow: 7px 7px 12px #aaaaaa;
    border-radius: 12px;
    padding: 12px;
    min-height: 411px;
}
.item_container p{
    text-align: center;
}