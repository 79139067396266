.profile_navbar {
  box-shadow: 2px 0px 8px 2px rgb(0 0 0 / 20%);
  border: rgba(0, 0, 0, 0.25) solid 1px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-bottom: rgba(128, 128, 128, 0.3) solid 1px;
}

.info img {
  width: 100px;
  height: 100px;
  border-radius: 25px;
}

.info p {
  font-weight: bold;
}

.links {
  padding: 15px 0px;
  width: 100%;
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: rgba(128, 128, 128, 0.3) solid 1px;
}

.link span {
  font-size: 25px;
}

.link p {
  margin-top: 20px;
  font-size: 20px;
}

.link:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.links:last-child {
  padding-bottom: 0px;
}

.last_link {
  border-bottom: none;
}

.active_link {
  background-color: #c463ec;
  color: white;
}

.active_link:hover {
  background-color: #c463ec;
}

.image_container {
  position: relative;
  width: fit-content;
}

.image_container label {
  position: absolute;
  bottom: -10px;
  left: -5px;
  color: black;
  font-size: 14px;
  cursor: pointer;
  background-color: silver;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.info button {
  background: #7d3c98;
  color: white;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 14px;
}

.info button:hover {
  background-color: #7c3c98e9;
}

.referralCode{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.referralCode span{
  background: rgba(201, 200, 200, 0.3);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap:20px;
  border-radius: 25px;
}

.referralCode span button{
  background: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 6px;
  transition: all 0.5s linear;
}

.referralCode span button:hover{
  background: white;
}


@media (width<=1140px) {
  .profile_navbar {
    border-radius: 25px;
    align-items: center;
    gap: 0px;
    padding: 0;
  }

  .links:first-child {
    align-items: center;
  }


  .info {
    border-bottom: none;
    padding: 10px 15px;
  }

  .link span {
    font-size: 25px;
  }

  .link p {
    margin-top: 20px;
    font-size: 20px;
  }

  .last_link {
    border-bottom-right-radius: 0px;
  }
}

@media (width <= 450px) {
  .links {
    height: 200px;
    overflow: auto;
  }
  .profile_navbar{
    overflow: hidden;
  }
}


