.CourseCard_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 7px 7px 12px #aaaaaa;
  margin-bottom: 12px;
  border-radius: 12px;
  height: fit-content;
}

.img_container{
  height: 210px ; 
}

.CourseCard_container .img_container img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 140px;
  width: 100%;
  height:100% ;
}

.CourseCard_container .btn_container a {
  background-color: #c463ec;
  padding: 7px 12px;
  border-radius: 7px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
}

.CourseCard_container .btn_container div {
  color: white;
}

.CourseCard_container .btn_container a:hover {
  background-color: #c563ece9;
}

.descriptionItem {
  display: flex;
  gap: 15px;
}

.descriptionItem p:first-child{
  font-weight: 600;
}

.discription{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:40px
}

@media(width<=1155px) {
  .CourseCard_container .img_container img{
    width: 80%;
  }

  .descriptionItem {
    font-size: 14px;
  }

  .seemore_link{
    font-size: 12px;
  }
}

@media(width<=800px) {
  .CourseCard_container .img_container{
    display: none;
  }
  .discription{
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }

  .CourseCard_container{
    justify-content: center;
  }


  .descriptionItem {
    font-size: 12px;
  }
}
