.page_item {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 12px;
  color: #000 !important;
  border: 1px solid #c563ec;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_item:hover,
.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: rgba(0, 0, 0, 0.1);
}
