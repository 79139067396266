.CourseCard_container {
    background-color: #fff;
    box-shadow: 7px 7px 12px #aaaaaa;
    margin-bottom: 12px;
    border-radius: 12px;
    overflow: hidden;
}

.CourseCard_container .img_container img {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 140px;
    width: 100%;
    height: 300px;
}

.CourseCard_container .btn_container div {
    border: 1px solid #c563ec;
    padding: 7px 12px;
    border-radius: 7px;
}

.CourseCard_container .btn_container div a {
    color: #000;
}

.CourseCard_container .btn_container div:hover {
    background-color: #c563ec;
}

.CourseCard_container .btn_container div:hover .seemore_link {
    color: #fff;
}