.packages_container {
  margin: 0 70px;
}

.title {
  font-size: 38px;
  font-weight: bold;
  word-spacing: 4px;
  text-align: center;
}

.card_container {
  display: flex;
  justify-content: space-between;
}

@media (width<=1200px) {
  .card_container {
    flex-direction: column;
    gap: 20px;
  }

  .title {
    font-size: 28px;
  }
}

@media (width<=700px) {
  .packages_container {
    margin: 0 22px;
  }
}

@media (width<=480px) {
  .title {
    font-size: 24px;
  }
}
