.navbar .nav-item a {
  font-weight: 500;
  color: #000 !important;
}
.navbar .active a {
  color: #c463ec !important;
}
.auth_container .nav-link {
  border-radius: 3px;
  border: 1px solid #c463ec;
}
.auth_container .login-link .login-text {
  color: #c463ec;
  font-weight: 500;
}
.auth_container .login-link:hover .login-text,
.auth_container .register-link p {
  color: #fff;
  cursor: pointer;
  font-weight: 500;
}
.auth_container .login-link:hover,
.auth_container .register-link {
  background-color: #c463ec !important;
}
.auth_container .register-link:hover {
  background-color: #fff !important;
}
.auth_container .register-link:hover .register-text {
  color: #c463ec;
}
