.app {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-fill-mode: both;
  font-family: "Montserrat", sans-serif;
}
form{
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
input {
  outline: none;
  border: none;
  background-color: transparent;
}
section {
  padding: 0 1%;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}
button:focus {
  outline: none;
}
@keyframes example {
  from {
    left: -2000px;
  }
  to {
    left: 0px;
  }
}
