.profile_tap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.name {
  font-size: 20px !important;
  font-weight: 600;
}

.profile_tap img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}
