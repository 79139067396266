.course_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 70px;
}
.course_container .nav-tabs .nav-link.active{
border: 0 !important;
}
.lesson_container {
  border-radius: 25px;
  border: rgba(0, 0, 0, 0.25) solid 1px;
  width: 80%;
  padding: 60px 80px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.overview_container {
  width: 60%;
}

.description {
  font-size: 22px;
  text-align: center;
}

@media (width <=700px) {
  .course_container {
    margin: 0 22px;
  }

  .lesson_container {
    width: 100%;
    padding: 40px;
  }

  .overview_container {
    width: 100%;
  }

  .title{
    font-size: 26px;
  }

  .description{
    font-size: 18px;
  }
}
