.privacyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .privacyContainer h2{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .privacyContainer h2 button{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

  }
  
  .title {
    text-align: center;
    font-size: 38px;
    font-weight: 600;
  }
  
  .contentContainer {
    border-radius: 25px;
    border: rgba(0, 0, 0, 0.25) solid 1px;
    width: 80%;
    min-height: 50vh;
    overflow: auto;
    padding: 50px 80px;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .content {
    font-size: 20px;
    font-weight: 500;
  }
  @media (width <=480px) {
    .privacyContainer{
        margin: 12px !important;
        display:block  !important;
    }
 .contentContainer {
        width: 100% !important;
        padding: 50px 22px;
    }
  }