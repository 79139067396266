.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 38px;
  font-weight: 600;
}

.contentContainer {
  border-radius: 25px;
  border: rgba(0, 0, 0, 0.25) solid 1px;
  width: 80%;
  min-height: 50vh;
  overflow: auto;
  padding: 50px 80px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.content {
  font-size: 20px;
  font-weight: 500;
}

.row {
  display: flex;
  gap: 20px;
}

.subTitle {
  font-size: 22px;
  font-weight: 600;
}
