.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.circle {
  width: 40px;
  height: 40px;
  border: #c463ec solid 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

.selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.correct {
  background-color: #d5f5e3;
}

.wrong {
  background-color: #fadbd8;
}

@media (width<=750px) {
  .container {
    gap: 3px;
  }

  .circle {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
