.profile_info_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header {
  font-size: 28px;
  font-weight: bold;
  border-bottom: rgba(0, 0, 0, 0.15) solid 2px;
}

.profile_info {
  display: flex;
  gap: 80px;
  font-size: 20px;
}

.titles {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.value {
  font-weight: 600;
  color: rgba(0, 0, 0);
}

@media (width<=750px) {
  .titles {
    column-gap: 10px;
    font-size: 14px;
  }

  .value {
    font-size: 14px;
  }

  .header {
    font-size: 24px;
  }

  .profile_info {
    gap: 15px;
    font-size: 16px;
  }
}
