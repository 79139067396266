.AuthForm_container {
  display: flex;
  align-self: center;
  justify-content: center;
}
.form_container {
  width: 60%;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.form_container form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input_container {
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
}
.input_container_date {
  width: 100%;
  padding: 12px 40px;
  background-color: #f2f2f2;
  border: 1px solid #8888;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
}
.radio_input label{
  margin-left: 15px;
}
.redio_btns_container{
  display: flex;
  gap: 40px;
}
.input_container a {
  color: #005561;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.error {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
  align-self: flex-end;
  color: #c85245;
}

.error_container {
  margin-bottom: 0px !important;
}

.input_container label {
  font-weight: bold;
}
.logo_container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: #888888;
  font-size: 20px;
  font-weight: 500;
}
.logo_container img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  color: #888888;
}

.logo_container .title {
  font-size: 32px;
  color: black;
  font-weight: bold;
}

.logo_container span {
  color: black;
  font-weight: bold;
}

.submit_button {
  font-size: 30px;
  font-weight: 500;
  background-color: #7d3c98;
  text-align: center;
  color: white;
  border-radius: 5px;
  width: 50%;
  padding: 8px 0px;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
  margin-top: 28px;
  margin-bottom: 20px;
}

.submit_button:hover {
  background-color: #7c3c98e9;
}

.login_option {
  color: #888888;
  font-size: 20px;
  font-weight: 500;
}

.login_option a {
  color: #1b98a1 !important;
  font-weight: 700;
  cursor: pointer;
}

.nameInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

/* PhoneInputCustom.css */
.custom-phone-input .special-label {
  display: none !important;
}

.custom-phone-input .form-control {
  height: 60px;
  width: 100% !important;
  padding: 10px;
  font-size: 16px;
}

.custom-phone-input .country-list {
  max-height: 200px;
  overflow-y: auto;
}

@media (width<=900px) {
  .form_container {
    width: 90%;
  }
  .form_container form {
    width: 95%;
  }
  .logo_container p {
    text-align: center;
  }
  .submit_button {
    width: 100%;
  }
  .error {
    font-size: 14px;
  }
  .nameInput {
    flex-direction: column;
    gap: 0px;
  }
}

@media (width <=480px) {
  .logo_container img {
    width: 100px;
    height: 100px;
  }
  .logo_container .title {
    font-size: 24px;
  }
  .logo_container {
    font-size: 16px;
  }
  .login_option {
    font-size: 16px;
    text-align: center;
  }
  .submit_button {
    font-size: 20px !important;
    width: 100%;
    padding: 4px 0px;
  }
  .error {
    font-size: 14px;
  }
}
