.profile_setting_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header {
  font-size: 28px;
  font-weight: bold;
  border-bottom: rgba(0, 0, 0, 0.15) solid 2px;
}

.btns {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.btns a {
  width: 20%;
  padding: 8px 0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 8px 0.5px rgb(0 0 0 / 20%);
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all;
  transition-duration: 500ms;
}

.btns a:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.active_link {
  color: white;
  background-color: #9747ff;
}

.active_link:hover {
  color: white !important;
  background-color: #9747ff !important;
}

@media (width <=750px) {
  .btns a{
    width: 50%;
    font-size: 16px;
  }
}
