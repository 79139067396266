.quiz_container {
  margin: 150px 70px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btns button {
  width: fit-content;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
  color: #c463ec;
  transition: all;
  transition-duration: 500ms;
}

.btns button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btns button p {
  padding-top: 20px;
  font-size: 22px;
  font-weight: 600;
}

.btns button span {
  font-size: 22px;
}

@media (width<=750px) {
  .btns button {
    padding: 0 10px;
  }

  .btns button span {
    font-size: 20px;
  }

  .quiz_container {
    margin: 150px 20px;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
}

@media (width<=480px) {
  .btns button {
    padding: 0 8px;
  }

  .btns button span {
    font-size: 14px;
  }
}
