.toTopBtn {
  position: fixed;
  bottom: 10px;
  right: 15px;
  padding: 10px 15px;
  font-size: 24px;
  background-color: #7d3c98;
  color: whitesmoke;
  opacity: 0.2;
  transition: all 500ms linear ;
  z-index: 50;
}

.toTopBtn:hover{
    opacity: 1;
}