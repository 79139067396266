form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}

.input_container {
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
}

.input_container label {
  font-weight: bold;
}

.input_container a {
  color: #005561;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.error {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
  align-self: flex-end;
  color: #c85245;
}

.submit_button {
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #c463ec;
  color: #c463ec;
  background-color: white;
  text-align: center;
  border-radius: 5px;
  width: 20%;
  padding: 8px 0px;
  align-self: self-start;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
}

.submit_button:hover {
  background-color: #c463ec;
  color: white;
}

@media (width<=750px) {
  .submit_button {
    width: 70%;
    padding: 4px 0px;
    align-self: center;
    font-size: 18px;
  }
}
