
.footer_container{
    background-color: #f8fdfc;
    

}
.footer-link p{
font-weight: 600;
color:#9e92a4;
}

.footer_container .footer_paragraph p{
    font-weight: 500;
    font-size: 18px;

}
.footer_container .footer_paragraph div p{
    font-weight:600 ;
}
.footer_container .footer_paragraph a{
    color: #000;
}

.social_media_icons{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.social_media_icons img{
    width: 40px;
    height:40px;
}