.profile_header {
  background: linear-gradient(
    to left,
    rgba(111, 56, 134, 0.4) 20%,
    rgba(154, 78, 185, 0.6) 60%,
    rgba(196, 99, 236, 0.8) 80%
  );

  width: 50%;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile_header img {
  width: 100px;
  height: 100px;
  border-radius: 25px;
}

.info {
  margin-top: 15px;
}

.info span {
  font-size: 20px;
  color: rgba(244, 208, 63, 0.8);
  font-weight: bold;
}

.info p {
  font-size: 26px;
  color: #3c0556;
  font-weight: bold;
}

@media (width<=1140px) {
  .profile_header {
    width: 90%;
    margin: auto;
  }
}
@media (width<=750px) {

  .info p {
    font-size: 20px;
  }

  .info span {
    font-size: 18px;
  }
}
